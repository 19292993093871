<template>
  <div class="shop-list">
    <section class="table-search">
      <div class="search-L">
        <el-select
          v-model="tableParams.merchantId"
          placeholder="请选择商户"
          @change="onMerchantSel"
          class="mr15"
        >
          <el-option
            v-for="item in commercialList"
            :key="item.merchantId"
            :label="item.merchantName"
            :value="item.merchantId"
          >
          </el-option>
        </el-select>
        <el-select
          v-model="tableParams.shopId"
          placeholder="请选择店铺"
          @change="onShopSel"
          class="mr15"
        >
          <el-option
            v-for="item in tableShopList"
            :key="item.shopId"
            :label="item.shopName"
            :value="item.shopId"
          >
          </el-option>
        </el-select>
        <el-select
          v-model="tableParams.gatewayId"
          placeholder="请选择关联网关"
          @change="reload"
          clearable
          class="mr15"
        >
          <el-option
            v-for="item in tableWayList"
            :key="item.gatewayId"
            :label="item.deviceName"
            :value="item.gatewayId"
          >
          </el-option>
        </el-select>
        <el-select
          v-model="tableParams.deviceType"
          placeholder="请选择设备类型"
          @change="reload"
          clearable
          class="mr15"
        >
          <el-option
            v-for="item in deviceTypeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-select
          v-model="tableParams.deviceStatus"
          placeholder="请选择设备状态"
          @change="reload"
          clearable
          class="mr15"
        >
          <el-option
            v-for="item in ObjToOpt(deviceStatusObj)"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="search-R">
        <el-button
          type="primary"
          @click="onChangeWay()"
          v-show="tableWayList.length > 0"
          >批量更换网关</el-button
        >
        <el-button type="primary" @click="onAdd()">新增设备</el-button>
      </div>
    </section>
    <!-- 表格 -->
    <el-table
      :data="tableData"
      ref="multipleTable"
      border
      stripe
      :header-cell-style="{
        background: '#FAFAFA',
        color: '#000000',
      }"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column label="设备名称" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.deviceName || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="设备类型" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.deviceTypeName || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="设备外部地址"
        align="center"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <span>{{ scope.row.deviceNumber || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="设备位置" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.deviceAddress || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="设备状态" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span :style="{ color: deviceStatusColor[scope.row.deviceStatus] }">{{
            deviceStatusObj[scope.row.deviceStatus]
          }}</span>
        </template>
      </el-table-column>
      <el-table-column width="200" label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="text" size="medium" @click.stop="onEdit(scope.row)"
            >编辑</el-button
          >
          <el-button type="text" size="medium" @click.stop="onDel(scope.row)"
            >拆除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="table-footer">
      <el-pagination
        layout="total,sizes, prev, pager, next,jumper"
        background
        :total="total"
        :page-size="tableParams.pageSize"
        @size-change="onSizeChange"
        :page-sizes="[10, 20, 50, 100]"
        :current-page.sync="currentPage"
        @current-change="onCurrentPage"
      >
      </el-pagination>
    </div>
    <!-- 编辑弹框 -->
    <el-dialog
      :visible.sync="editShow"
      :title="editTitle"
      :close-on-click-modal="false"
      width="50%"
      custom-class="add-dialog"
    >
      <el-form :model="params" :rules="rules" ref="form-ref" inline>
        <!-- 商户列表 -->
        <el-form-item label="所属商户" label-width="120px" prop="merchantId">
          <el-select
            v-model="params.merchantId"
            placeholder="请选择所属商户"
            filterable
            @change="onMerchant()"
            :disabled="editTitle == '编辑服务'"
          >
            <el-option
              v-for="item in commercialList"
              :key="item.merchantId"
              :label="item.merchantName"
              :value="item.merchantId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!-- 店铺列表 -->
        <el-form-item label="所属店铺" label-width="120px" prop="shopId">
          <el-select
            v-model="params.shopId"
            placeholder="请选择所属店铺"
            @change="onShop()"
            :disabled="editTitle == '编辑服务'"
          >
            <el-option
              v-for="item in shopList"
              :key="item.shopId"
              :label="item.shopName"
              :value="item.shopId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!-- 设备类型 -->
        <el-form-item label="设备类型" label-width="120px" prop="deviceType">
          <el-select
            v-model="params.deviceType"
            placeholder="请选择设备类型"
            @change="onDeviceModel"
            :disabled="editTitle == '编辑服务'"
          >
            <el-option
              v-for="item in deviceTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!-- 服务商 -->
        <el-form-item
          label="设备服务商"
          label-width="120px"
          prop="deviceServiceType"
        >
          <el-select
            v-model="params.deviceServiceType"
            placeholder="请选择设备服务商"
            @change="onDeviceModel"
          >
            <el-option
              v-for="item in spList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!-- 外部地址 -->
        <el-form-item label="外部地址" label-width="120px" prop="deviceNumber">
          <el-input
            v-model.trim="params.deviceNumber"
            autocomplete="off"
            maxlength="20"
            placeholder="请填写外部地址"
            :disabled="editTitle == '编辑服务'"
          ></el-input>
        </el-form-item>
        <!-- 设备位置 -->
        <el-form-item label="设备位置" label-width="120px" prop="roomId">
          <el-select
            v-model="params.roomId"
            placeholder="请选择设备位置"
            @change="change()"
          >
            <el-option
              v-for="item in roomList"
              :key="item.roomId"
              :label="item.roomName"
              :value="item.roomId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!-- 设备名称 -->
        <el-form-item label="设备名称" label-width="120px" prop="deviceName">
          <el-input
            v-model.trim="params.deviceName"
            autocomplete="off"
            maxlength="8"
            placeholder="请填写设备名称"
          ></el-input>
        </el-form-item>
        <!-- 关联网管 -->
        <el-form-item label="关联网关" label-width="120px" prop="gatewayId">
          <el-select
            v-model="params.gatewayId"
            placeholder="请选择关联网关"
            @change="change()"
          >
            <el-option
              v-for="item in wayList"
              :key="item.gatewayId"
              :label="item.deviceName"
              :value="item.gatewayId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!-- 电源路数 -->
        <el-form-item
          label="电源路数"
          label-width="120px"
          prop="powerChannels"
          v-if="params.deviceType == 11"
        >
          <el-input
            v-model.trim="params.powerChannels"
            autocomplete="off"
            maxlength="8"
            placeholder="请填写电源路数"
          ></el-input>
        </el-form-item>
        <!-- 设备型号 -->
        <el-form-item label="设备型号" label-width="120px" prop="deviceModel">
          <el-select
            v-model="params.deviceModel"
            placeholder="请选择设备型号"
            @change="change()"
          >
            <el-option
              v-for="item in deviceModelList"
              :key="item.deviceModelId"
              :label="item.deviceModel"
              :value="item.deviceModel"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editShow = false">取 消</el-button>
        <el-button type="primary" @click="onSubmit('form-ref')">保存</el-button>
      </div>
    </el-dialog>
    <!-- 批量修改网关弹窗 -->
    <el-dialog
      title="批量修改网关"
      :visible.sync="getWayShow"
      :close-on-click-modal="false"
      append-to-body
      width="30%"
    >
      <el-form
        :model="wayParams"
        :rules="rules"
        ref="form-way"
        label-width="100px"
      >
        <section class="form-main">
          <el-form-item label="关联网关" prop="gatewayId">
            <el-select
              v-model="wayParams.gatewayId"
              placeholder="请选择关联网关"
              @change="change()"
              clearable
              class="mr15"
            >
              <el-option
                v-for="item in tableWayList"
                :key="item.gatewayId"
                :label="item.deviceName"
                :value="item.gatewayId"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </section>
        <section class="form-footer">
          <el-button type="primary" @click="onWaySubmit('form-way')"
            >保存</el-button
          >
        </section>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  getDeviceList,
  getRoomList,
  getDeviceType,
  getSpList,
  getWayList,
  getDeviceAdd,
  getDeviceEdit,
  getDeviceDel,
  getChangeWay,
  getDeviceModelList,
} from "@/api/device/device";
import { getAutoCommercialList } from "@/api/commercial/commercial";
import { getIdShopList } from "@/api/commercial/shop";
import { ObjToOpt } from "@/utils/utils";
import { rules } from "@/db/rules";
import {
  merchantStatusObj,
  deviceStatusObj,
  deviceStatusColor,
} from "@/db/objs";
export default {
  data() {
    return {
      rules,
      ObjToOpt,
      merchantStatusObj,
      deviceStatusObj,
      deviceStatusColor,
      commercialList: [], // 商户列表
      tableShopList: [], //店铺列表
      tableWayList: [], //网关列表
      // 列表
      currentPage: 1, // 当前页
      total: 0, // 总条数
      tableData: [],
      tableParams: {
        page: 1,
        pageSize: 10,
        merchantId: "", //商户ID
        shopId: "", //店铺ID
        deviceType: "", //设备类型
        gatewayId: "", //网关
        deviceStatus: "", //设备状态
      },
      // 表单
      editShow: false, //弹框开关
      editTitle: "", //弹框姓名
      shopList: [], //店铺列表
      roomList: [], //位置列表（服务+房间）
      deviceTypeList: [], //设备类型
      spList: [], //服务商列表
      wayList: [], //网关列表
      deviceModelList: [], //设备型号列表
      params: {
        merchantId: "", //商户ID
        shopId: "", //店铺ID
        serviceId: "", //服务ID
        deviceName: "", //设备名称
        deviceModel: "", //设备型号
        roomId: "", //位置
        deviceType: "", //设备类型
        deviceServiceType: "", //服务商
        gatewayId: "", //网关
        powerChannels: "", //电源路数
      },
      // 批量修改网关
      getWayShow: false,
      wayParams: {
        deviceIds: "",
        gatewayId: "",
      },
    };
  },
  mounted() {
    this.getAutoCommercialList(); // 【请求】商户下拉列表
    this.getDeviceType(); // 【请求】设备类型
    this.getSpList(); //【请求】服务商列表
  },
  methods: {
    // 【请求】列表
    getDeviceList() {
      let data = this.tableParams;
      getDeviceList(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },

    // 【请求】商户下拉列表
    getAutoCommercialList() {
      getAutoCommercialList().then((res) => {
        if (res.isSuccess == "yes") {
          this.commercialList = res.data;
          if (res.data && res.data.length > 0) {
            this.tableParams.merchantId = res.data[0].merchantId;
            this.getTableIdShopList();
          }
        }
      });
    },

    // 【请求】店铺列表
    getIdShopList() {
      let data = {
        merchantId: this.params.merchantId,
      };
      getIdShopList(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.shopList = res.data;
        }
      });
    },

    // 【请求】位置列表（服务+房间）
    getRoomList() {
      let data = {
        merchantId: this.params.merchantId,
        shopId: this.params.shopId,
      };
      getRoomList(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.roomList = res.data;
        }
      });
    },

    // 【请求】设备类型
    getDeviceType() {
      getDeviceType().then((res) => {
        if (res.isSuccess == "yes") {
          this.deviceTypeList = res.data;
        }
      });
    },

    // 【请求】服务商列表
    getSpList() {
      getSpList().then((res) => {
        if (res.isSuccess == "yes") {
          this.spList = res.data;
        }
      });
    },

    // 【请求】网关列表
    getWayList() {
      let data = {
        shopId: this.params.shopId,
      };
      getWayList(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.wayList = res.data;
        }
      });
    },

    // 【请求】表格网关列表
    getTableWayList() {
      let data = {
        shopId: this.tableParams.shopId,
      };
      getWayList(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.tableWayList = res.data;
        }
      });
    },

    // 【请求】新建设备
    getDeviceAdd() {
      let data = this.params;
      getDeviceAdd(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.$message({
            message: res.message,
            type: "success",
          });
          this.editShow = false;
          this.reload();
        }
      });
    },

    // 【请求】编辑设备
    getDeviceEdit() {
      let data = this.params;
      getDeviceEdit(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.$message({
            message: res.message,
            type: "success",
          });
          this.editShow = false;
          this.getDeviceList();
        }
      });
    },

    // 【请求】筛选商户店铺列表
    getTableIdShopList() {
      let data = {
        merchantId: this.tableParams.merchantId,
      };
      getIdShopList(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.tableShopList = res.data;
          if (res.data.length > 0) {
            this.tableParams.shopId = res.data[0].shopId;
            this.getTableWayList();
          }
          this.getDeviceList(); // 【请求】列表
        }
      });
    },

    // 【请求】批量更换设备
    getChangeWay() {
      let data = this.wayParams;
      getChangeWay(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.getWayShow = false;
          this.$message({
            message: res.message,
            type: "success",
          });
          this.getDeviceList();
        }
      });
    },

    // 【请求】设备型号列表（不分页）
    getDeviceModelList() {
      let data = this.params;
      getDeviceModelList(data).then((res) => {
        if (res.isSuccess == "yes") {
          if (res.data) {
            this.deviceModelList = res.data;
          }
        }
      });
    },

    // 【监听】表格商户筛选
    onMerchantSel() {
      this.tableParams.shopId = "";
      this.tableParams.gatewayId = "";
      this.reload();
      if (this.tableParams.merchantId) {
        this.getTableIdShopList();
      } else {
        this.tableShopList = [];
      }
    },

    // 【监听】筛选店铺
    onShopSel() {
      this.$forceUpdate();
      this.tableParams.gatewayId = "";
      this.getTableWayList();
      this.reload();
    },

    // 【监听】表格重载
    reload() {
      this.tableParams.page = 1;
      this.currentPage = 1;
      this.getDeviceList();
    },

    // 【监听】表格分页点击
    onCurrentPage(page) {
      this.tableParams.page = page;
      this.getDeviceList();
    },

    // 【监听】表格条数点击
    onSizeChange(pageSize) {
      this.tableParams.pageSize = pageSize;
      this.reload();
    },

    // 【监听】弹框商户选择
    onMerchant() {
      this.getIdShopList();
      this.params.shopId = "";
    },

    // 【监听】弹框店铺选择
    onShop() {
      this.$forceUpdate();
      this.params.roomId = "";
      this.params.gatewayId = "";
      this.getRoomList();
      this.getWayList();
    },

    // 【修复】下拉不回显
    change() {
      this.$forceUpdate();
    },

    // 【监听】设备型号
    onDeviceModel() {
      this.$forceUpdate();
      if (this.params.deviceType && this.params.deviceServiceType) {
        this.getDeviceModelList();
      }
    },

    // 【监听】新增
    onAdd() {
      this.editShow = true;
      this.editTitle = "新增设备";
      this.params = {
        merchantId: "", //商户ID
        shopId: "", //店铺ID
        serviceId: "", //服务ID
        deviceName: "", //设备名称
        deviceModel: "", //设备型号
        roomId: "", //位置
        deviceType: "", //设备类型
        deviceServiceType: "", //服务商
        gatewayId: "", //网关
        powerChannels: "", //电源路数
      };
      this.deviceModelList = [];
      this.$nextTick(() => {
        this.$refs["form-ref"].clearValidate();
      });
    },

    // 【监听】编辑
    onEdit(row) {
      this.editShow = true;
      this.editTitle = "编辑服务";
      this.params = JSON.parse(JSON.stringify(row));
      this.getIdShopList();
      this.getRoomList();
      this.getWayList();
      this.getDeviceModelList();
      this.$nextTick(() => {
        this.$refs["form-ref"].clearValidate();
      });
    },

    // 【监听】表单提交
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.params.deviceId) {
            this.getDeviceEdit();
          } else {
            this.getDeviceAdd();
          }
        } else {
          return false;
        }
      });
    },

    // 【监听】设备拆除
    onDel(row) {
      let data = {
        deviceId: row.deviceId,
      };
      this.$confirm("你确定要拆除该设备吗?", "设备拆除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
      }).then(() => {
        getDeviceDel(data).then((res) => {
          if (res.isSuccess == "yes") {
            this.$message({
              message: res.message,
              type: "success",
            });
            this.getDeviceList();
          }
        });
      });
    },

    // 【监听】批量更换网关
    onChangeWay() {
      this.wayParams.gatewayId = "";
      this.wayParams.deviceIds = [];
      let arr = this.$refs.multipleTable.selection;
      if (arr.length == 0) return this.$message.error("请选择设备");
      for (let i of arr) {
        this.wayParams.deviceIds = [...this.wayParams.deviceIds, i.deviceId];
      }
      this.getWayShow = true;
    },

    // 【监听】表单提交
    onWaySubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.getChangeWay();
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.shop-list {
  padding: 0.15rem;
  box-sizing: border-box;
}

.table-search {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.15rem;
}

.search-L {
  display: flex;
}

.table-footer {
  margin-top: 0.1rem;
  display: flex;
  justify-content: flex-end;
}

.form-footer {
  display: flex;
  justify-content: flex-end;
}
</style>